<template>
  <div>
    <section class="background-section"></section>
    <section class="sobre-nos">
      <div class="topo">
        <h2>Sobre Nós</h2>
        <p>
          A OverX é uma plataforma completa para gestão de restaurantes e delivery,
          focada em simplificar operações e fidelizar clientes com um atendimento
          de alta qualidade. Nossa missão é proporcionar eficiência e controle total
          em um único sistema, para que você possa gerenciar todos os aspectos do seu
          negócio com facilidade.
        </p>
      </div>
      <div class="area-imagens">
        <div class="imagem imagem-destaque">
          <img src="@/assets/foto1.png" alt="Foto principal" />
        </div>
        <div class="imagem">
          <img src="@/assets/foto2.png" alt="Foto 2" />
        </div>
        <div class="imagem">
          <img src="@/assets/foto3.png" alt="Foto 3" />
        </div>
      </div>
      <div class="cartao-escuro">
        <p>
          Com tecnologia de ponta e uma abordagem inovadora, oferecemos uma plataforma
          integrada que promove economia, produtividade e satisfação dos clientes.
          Aumente suas vendas, reduza custos e potencialize seu negócio com a OverX.
        </p>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: 'SobreNos',
};
</script>

<style scoped>
.background-section {
  background-image: url("@/assets/sobre.png");
    background-size: contain;
    background-position: center top;
    background-repeat: no-repeat;
    width: 100%;
    height: 100vh;
    padding-top: 5rem;
  }
  
  @media (max-width: 1020px) {
    .background-section {
      display: none;
    }
  }
  
  .sobre-nos {
    display: none;
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem 1rem;
    font-family: sans-serif;
  }
  
  @media (max-width: 1020px) {
    .sobre-nos {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 2rem;
    }
  }
  
  .topo {
    text-align: center;
    max-width: 800px;
  }
  
  .topo h2 {
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 1rem;
  }
  
  .topo p {
    font-size: 1.1rem;
    line-height: 1.6;
    color: #333;
  }
  
  .area-imagens {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
    width: 100%;
    max-width: 800px;
  }
  
  .imagem {
    flex: 1 1 auto;
    min-width: 220px;
    display: flex;
    justify-content: center;
  }
  
  .imagem img {
    background-color: transparent;
    width: 100%;
    max-width: 280px;
    height: auto;
    border-radius: 0.5rem;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    object-fit: cover;
  }
  
  .cartao-escuro {
    background-color: #333;
    color: #fff;
    padding: 1.5rem 2rem;
    border-radius: 0.5rem;
    max-width: 800px;
    text-align: center;
}

.cartao-escuro p {
  margin: 0;
  font-size: 1.1rem;
  line-height: 1.6;
}
</style>
